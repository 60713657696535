import { WorldLayer } from "../world_layer"
import { ContextMenu } from "../ui/context_menu"

class IslandContextMenu extends ContextMenu {
  init() {
    this.opts.boundary = this.layer.world.worldParentEl

    this.addHeader(`${this.island.gridPos.join("")} | ${this.island.name}`)
    if(this.opts.alt) this.addHeader(`${this.island.id}`, { class: "text-smol pt-1 mt-n2" })

    this.addButton("centerInViewportZoom", "center on bounds", { icon: { ico: "bullseye", class: "me-1" }, class: "fw-bold", click: true })
    this.addButton("centerInViewport", "center in view", { icon: { ico: "bullseye", class: "me-1" }, click: true })
    // this.addDivider()
    // this.addButton("openSingle", "open island", { click: ev => { ev.preventDefault(); }})
    this.addButton("openWiki", "open wiki page", { icon: { ico: "wikipedia", class: "me-1" }, click: true })

    if(this.opts.alt) {
      this.addDivider()
      this.addButton("detailModal", "show details", { icon: { ico: "list", class: "me-1" }, click: true })
      this.addButton("referenceIsland", "global reference", { icon: { ico: "link", class: "me-1" }, click: true })
      this.addButton("removeIsland", "destroy island", { icon: { ico: "x-lg", class: "me-1" }, class: "bg-danger", click: true })
    }
  }

  centerInViewport(ev) {
    ev.preventDefault()
    this.island.centerInViewport(false, { animate: false })
  }

  centerInViewportZoom(ev) {
    ev.preventDefault()
    this.island.centerInViewport("auto", { animate: false })
  }

  detailModal(ev) {
    ev.preventDefault()
    this.layer.toastNotification(`Island details modal not yet implemented`, { type: "error" })
  }

  openWiki(ev) {
    ev.preventDefault()
    const wikiName = this.island.name.replace(/\s/, "_").replace(/’/, "'")
    window.open(`https://seaofthieves.wiki.gg/wiki/${wikiName}`, "_blank", "noopener,noreferrer")
  }

  referenceIsland(ev) {
    ev.preventDefault()
    window.island = this.island
    this.layer.toastNotification(`Island has been referenced to <code>window.island</code>`)
  }

  removeIsland(ev) {
    ev.preventDefault()
    this.layer.world.islands.destroyIsland(this.island)
  }
}

export class IslandNames extends WorldLayer {
  init() {
    this.enableInitially = true
    this.mayRenderAsync = true
    this.islandsReady = false
    this.world.islands.oneIslandEverInView.then(_ => {
      this.islandsReady = true
      this.update()
    })

    this.optreg.add("str", "nameColor", "#222d").desync()
    this.optreg.add("str", "nameColorHover", "#111f").desync()
    this.optreg.add("bool", "internalNames", false)

    this.world.cursor.addFilter(this, "findIslandFromNameUnderPointer", { hoverCursor: "pointer", onHover: (island, ev, c) => {
      island.nameText.updateOpts({ color: this.o("nameColorHover") }, "redraw")
    }, onBlur: (island, ev, c) => {
      island.nameText.updateOpts({ color: this.o("nameColor") }, "redraw")
    }, onClick: (island, ev, c) => {
      island.centerInViewport("auto", { animate: false })
      return false
    }, onRightClick: (island, ev, c) => {
      document.cancelNextContextmenu = true // cannot preventDefault in pointerup
      this.world.openContextmenu(new IslandContextMenu({ alt: ev.altKey, assign: { island: island, layer: this }}), ev)
      return false
    }})
  }

  startup() {
    this.getLayer("GridForeground")?.children?.set("IslandNames", this)
  }

  findIslandFromNameUnderPointer(ev) {
    if(!this.enabled) return
    if(this.world.panzooming && this.world.mouseDeltaThresholdAny(1)) return
    const point = [ev.clientX - this.worldParentRect.left, ev.clientY - this.worldParentRect.top]

    for(const island of this.world.islands.inView) {
      if(!island.nameText?.clickBox) continue
      if(this.pointIntersectBox(point, island.nameText.clickBox)) {
        return island
      }
    }
  }

  render() {
    if(!this.enabled) return
    if(!this.islandsReady) return
    this.clear()

    this.trx(ctx => {
      const fontSize = this.zoomVec(8, 1.5, 5)
      this.world.islands.inView.forEach(island => {
        if(!island.nameVisible) return false

        island.nameText ??= this.createText(island.name, { anchor: [-0.5, -2], color: this.o("nameColor") })
        island.nameText.opts.font = `${fontSize}px windlass-extended`
        island.nameText.update()

        const w = island.activeResolution?.w ?? island.boundsRadius
        island.nameText.draw(island.position[0], island.position[1] - (w/2), true)
        island.nameText.clickBox = this.padBox(island.nameText.boundingBox, island.nameText.actualHeight / 2)

        if(this.o("internalNames") && this.world.vscale > 16) {
          // ${island.rotation.toFixed(2)}°
          island.internalNameText ??= this.createText(`${island.id}`, { anchor: [-0.5, -2], color: this.o("nameColor") })
          island.internalNameText.opts.font = `${this.zoomVec(6, 1.5, 5)}px windlass-extended`
          island.internalNameText.update()

          const w = island.activeResolution?.w ?? island.boundsRadius
          island.internalNameText.draw(island.position[0], island.position[1] - (island.nameText.actualHeight * this.world.realScale) - 300 - (w/2), true)
          // island.internalNameText.clickBox = this.padBox(island.nameText.boundingBox, island.nameText.actualHeight / 2)
        }
      })
    })
  }
}
