export class ComponentLoader {
  constructor(app) {
    this.app = app
    this.promises = new Map()
    this.sourceMap = new Map()
    this.exports = new Map()
  }

  destroy() {
    delete this.promises
    delete this.sourceMap
    delete this.exports
  }

  refreshSources() {
    this.sourceMap = new Map()
    if(document.body?.dataset.apposComponentMap) {
      this.sourceMap = new Map(Object.entries(JSON.parse(document.body.dataset.apposComponentMap)))
    }
    return this
  }

  signal(comp, exps = {}, use = []) {
    if(!this.exports.has(comp)) this.exports.set(comp, new Map())
    const emap = this.exports.get(comp)
    Object.entries(exps).forEach(([name, exp]) => emap.set(name, exp))

    document.body.dispatchEvent(
      new CustomEvent("appos:component:loaded", {
        bubbles: true,
        detail: { component: comp, exports: exps },
      }),
    )

    use.forEach(exp => {
      const ins = this.app?.use(exp)
      ins.boot?.()
      ins.documentLoad?.()
      ins.pageLoad?.()
    })
  }

  ensure() {
    this.refreshSources()
    const promises = new Map()
    document.querySelectorAll(`[data-appos-require-component]`).forEach(el => {
      if(!el.dataset.apposRequireComponent) return undefined
      el.dataset.apposRequireComponent.split(" ").forEach(comp => {
        if(comp && !promises.has(comp)) promises.set(comp, this.load(comp))
      })
    })
    return Promise.all(promises.values())
  }

  load(component) {
    if(!this.promises.has(component)) {
      if(this.sourceMap.has(component)) {
        this.promises.set(component, this.loadComponentWithScriptTag(component, this.sourceMap.get(component)))
      } else {
        console.warn(`[AppOS] failed to load component, not in map:`, component)
      }
    }
    return this.promises.get(component)
  }

  loadComponentWithScriptTag(component, src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script")
      script.type = "text/javascript"
      script.src = src
      script.defer = true
      script.setAttribute("data-appos-component", component)
      // script.setAttribute("data-turbo-track", "reload")
      script.onload = ev => resolve([component, this.exports.get(component), src, script, ev])
      document.head.append(script)
    })
  }
}
