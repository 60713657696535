import {Application} from "./application"
// import {DomObserver} from "./dom_observer"
import {ComponentLoader} from "./component_loader"
import {Component} from "./component"
import {NamespacedConsole} from "./namespaced_console"
import {SimpleEvents} from "./simple_events"
import {Debugger} from "./debugger"
import {Util} from "./util"

class AppOS {
  static boot(app) {
    app.events.fire("init", this)
    app.opts.defaultBoot?.apply(app, [app])

    AppOS.setupTemplateHooksSingleton(app)
    // AppOS.setupDomObserverSingleton(app)
    AppOS.setupComponentLoaderSingleton(app)

    app.events.fire("boot", app)
    return app
  }

  static setupTemplateHooksSingleton(app) {
    if(!window.__appos_core_template_hooks_registered) {
      this.setupTemplateHooks(app)
      window.__appos_core_template_hooks_registered = true
    }
  }

  static setupTemplateHooks(app) {
    app.events.on("boot", _ => { AppOS.runTemplateHooks("app-boot", { app: app }) })
    app.events.on("documentLoad", _ => { AppOS.runTemplateHooks("document-load", { app: app }) })
    app.events.on("pageLoad", _ => { AppOS.runTemplateHooks("page-load", { app: app }) })
  }

  static setupComponentLoaderSingleton(app) {
    if(!window.__appos_core_component_loader) {
      this.setupComponentLoader(app)
    }
  }

  static setupComponentLoader(app) {
    window.__appos_core_component_loader?.destroy()
    window.__appos_core_component_loader = new ComponentLoader(app)
    window.__appos_component_loaded = window.__appos_core_component_loader.signal.bind(window.__appos_core_component_loader)
    app?.events.on("pageLoad", _ => { window.__appos_core_component_loader.ensure() })
  }

  // static setupDomObserverSingleton(app) {
  //   if(!window.__appos_core_dom_observer) {
  //     this.setupDomObserver(app)
  //   }
  // }

  // static setupDomObserver(app) {
  //   window.__appos_core_dom_observer?.destroy()
  //   window.__appos_core_dom_observer = new DomObserver(document.documentElement)
  //   app.events.on("documentLoad", _ => window.__appos_core_dom_observer?.start())
  //   return window.__appos_core_dom_observer
  // }

  static runTemplateHooks(type, env = {}) {
    document.querySelectorAll(`template[appos-${type}]`).forEach(tpl => {
      const f = new Function('env', tpl.content.firstElementChild.textContent)
      if(typeof env?.app?.debug == "function") env.app.debug("running tpl-hook", type, tpl)
      f(env)
      tpl.remove()
    })
  }

  static mergeClasses(...bases) {
    class Bases {
      constructor() {
        bases.forEach(base => Object.assign(this, new base()));
      }
    }
    bases.forEach(base => {
      Object.getOwnPropertyNames(base.prototype)
      .filter(prop => prop != 'constructor')
      .forEach(prop => Bases.prototype[prop] = base.prototype[prop])
    })
    return Bases;
  }

  static mergeClassesAsModule(...bases) {
    class Bases {}
    bases.forEach(base => {
      Object.getOwnPropertyNames(base.prototype)
      .filter(prop => prop != 'constructor')
      .forEach(prop => Bases.prototype[prop] = base.prototype[prop])
    })
    return Bases;
  }

  static html(str) {
    const template = document.createElement("template")
    template.innerHTML = str
    return template.content.firstElementChild
  }

  static htmlAll(str) {
    const template = document.createElement("template")
    template.innerHTML = str
    return template.content.childNodes
  }
}

const bootFunction = AppOS.boot
const mergeClassesFunction = AppOS.mergeClasses
const mergeClassesAsModuleFunction = AppOS.mergeClassesAsModule

export {
  bootFunction as boot,
  mergeClassesFunction as mergeClasses,
  mergeClassesAsModuleFunction as mergeClassesAsModule,
  AppOS,
  Debugger,
  Application,
  Component,
  SimpleEvents,
  NamespacedConsole,
  Util,
}
