import { WorldLayer } from "../world_layer"
import { SotIngameTime } from "../../sot/ingame_time"
import { JsonDataAsset } from "../asset_loader"
import { Animation } from "../animation"

class StormData extends JsonDataAsset {
  normalizeVindex(vi) {
    while(vi > this.data.length - 1) vi -= this.data.length
    while(vi < 0) vi += this.data.length
    return vi
  }

  atVindex(vi) { return this.data[this.normalizeVindex(vi)] }
}

class StormLayer extends WorldLayer {
  calculateControlPoints(p0, p1, t0, t1, alpha = 1 / 3) {
    return [
      [p0[0] + alpha * t0[0], p0[1] + alpha * t0[1]],
      [p1[0] - alpha * t1[0], p1[1] - alpha * t1[1]],
    ]
  }

  linspace(start, stop, num) {
    const result = []
    const step = (stop - start) / (num - 1)
    for (let i = 0; i < num; i++) {
      result.push(start + step * i)
    }
    return result
  }

  getStormIndexDetail() {
    const idx = {}
    const fakeIndex = this.co("fakeIndex")
    const useFake = this.mayUseFake
    if(useFake && this.mayUseFakeIndex && fakeIndex !== null) {
      idx.float = fakeIndex
    } else {
      let time = 0
      const fakeTime = this.co("fakeTime")
      if(useFake && this.mayUseFakeTime && fakeTime !== null) {
        time = fakeTime
      } else {
        const tdelta = useFake && this.mayUseFakeDelta ? (this.co("fakeTimeDelta") ?? this.co("timeDelta")) : this.co("timeDelta")
        time = Date.now() + (tdelta * 1000)
      }
      idx.float = SotIngameTime.stormIndex(time)
    }
    // idx.float = 0.2
    // idx.float = 360.2
    idx.base = Math.floor(idx.float)
    idx.t = Math.abs(idx.float % 1)
    idx.lower = idx.base - this.co("tail")
    idx.upper = idx.base + this.co("tongue")
    // console.log(JSON.stringify(idx))
    return idx
  }

  prepareStormIndex(idx) {
    if(this.mayUseFake && this.mayUseFakeDelta) this.currentIndex = idx
    return this.currentIndex ??= this.getStormIndexDetail()
  }

  splitBezier(P0, P1, P2, P3, t) {
    // Calculate the intermediate points
    const P0_prime = this.interpolate(P0, P1, t)
    const P1_prime = this.interpolate(P1, P2, t)
    const P2_prime = this.interpolate(P2, P3, t)

    const P0_double_prime = this.interpolate(P0_prime, P1_prime, t)
    const P1_double_prime = this.interpolate(P1_prime, P2_prime, t)

    const P = this.interpolate(P0_double_prime, P1_double_prime, t)

      // Return the control points for the two segments
    return [
      [P0, P0_prime, P0_double_prime, P],
      [P, P1_double_prime, P2_prime, P3]
    ]
  }

  interpolate(point1, point2, t) {
    return [
      (1 - t) * point1[0] + t * point2[0],
      (1 - t) * point1[1] + t * point2[1]
    ]
  }
}



export class Storm extends StormLayer {
  init() {
    this.children = new Map()
    this.animations = new Map()
    this.animating = 0
    this.stormdata = this.assets.add(StormData, "storm-nodes.json", this.world.world.data("fetchStormData")).on("load", _ => this.update())
    this.currentIndex = null
    this.mayUseFake = true
    this.mayUseFakeDelta = true
    this.mayUseFakeIndex = true
    this.mayUseFakeTime = true
    // this.debounceRenderWithFade = 100

    this.optreg.add("float", "thickness", 2).desync()
    this.optreg.add("float", "minThickness", 2).desync()
    this.optreg.add("float", "maxScale", 1.5).desync()

    this.optreg.add("int",   "updateEyeAnimEvery", Math.floor(1000 / 10)).desync()
    this.optreg.add("int",   "updateEyeEvery", 500).desync()
    this.optreg.add("int",   "updateStormTimesEvery", 500).desync()
    this.optreg.add("bool",  "splitActiveSegment", true)
    this.optreg.add("int",   "updateActiveSegmentEvery", 1000).desync()

    this.optreg.add("int",   "tail", 3)
    this.optreg.add("int",   "tongue", 15)
    this.optreg.add("float", "pathSmoothingAlpha", 1/3)
    this.optreg.add("enum",  "renderControlPoints", "off", { enum: ["off", "on", "withControls", "detailed"] })
    this.optreg.add("bool",  "renderProgressText", false)
    this.optreg.add("bool",  "renderDirectionArrow", true)
    this.optreg.add("bool",  "renderStormTimes", false)
    this.optreg.add("float", "eyeAlpha", 0.9)
    this.optreg.add("bool",  "animateStorm", false).onChange(_ => this.trackedUpdateInterval && this.startTrackedUpdate())
    this.optreg.add("int",   "progressTextPrecision", 0)
    this.optreg.add("int",   "timeDelta", 0)
    this.optreg.add("int",   "fakeTimeDelta", null, { allowNull: true }).desync()
    this.optreg.add("float", "fakeIndex", null, { precision: 3, allowNull: true, castNanToNull: true })
    this.optreg.add("date",  "fakeTime", null, { allowNull: true, castNanToNull: true })

    this.optreg.add("str",   "colors.stormCenter", "#000000cc").onChange(_ => this.cacheColors())
    this.optreg.add("str",   "colors.stormArrow", "#cc0000dd").onChange(_ => this.cacheColors())
    this.optreg.add("str",   "colors.stormInner", "red").onChange(_ => this.cacheColors())
    this.optreg.add("str",   "colors.stormRadius", "orange").onChange(_ => this.cacheColors())
    this.optreg.add("str",   "colors.stormOuter", "aqua").onChange(_ => this.cacheColors())
    this.cacheColors()

    this.optreg.cmd("!ff-12h", async cmd => {
      const anim = this.animations.get("positionFF")
      if(cmd.value) {
        if(anim.running) anim.stop() // if not running we are waiting for data
      } else {
        anim.ff(12, 1000)
      }
    })

    this.optreg.cmd("!ff-select", async cmd => {
      const anim = this.animations.get("positionFF")
      const fcmd = this.coo("!ff-12h")
      if(fcmd.value) {
        if(anim.running) anim.stop() // if not running we are waiting for data
      } else {
        const config = prompt("Choose hours @ speed (seconds per hour)", "12 @ 4")
        if(!config) return
        const chunks = config.split("@")
        const hours = parseInt(chunks[0])
        const speed = parseFloat(chunks[1]) * 1000
        if(isNaN(hours) || isNaN(speed)) {
          this.toastNotification("Invalid input provided!")
        } else {
          anim.ff(hours, speed)
        }
      }
    })

    this.setupAnimations()
    this.animations.get("positionFF").on("start", _ => this.oo("!ff-12h").value = true).on("ended", _ => this.oo("!ff-12h").value = false)
  }

  cacheColors() {
    this.cachedColors = this.co("colors")
  }

  co(...args) { return this.o(...args) }
  coo(...args) { return this.oo(...args) }

  initPost() {
    this.children.set("StormPath", new StormPath(this.world, {}, this))
    this.children.set("ActiveSegment", new ActiveSegment(this.world, {}, this))
    this.children.set("StormEye", new StormEye(this.world, {}, this))
    this.children.set("StormTimes", new StormTimes(this.world, {}, this))
  }

  get eye() { return this.children.get("StormEye") }
  get path() { return this.children.get("StormPath") }
  get activeSegment() { return this.children.get("ActiveSegment") }
  get stormTimes() { return this.children.get("StormTimes") }

  onEnable() {
    this.stormdata.load()
    this.children.forEach((c, k) => c.enable?.())
    this.startTrackedUpdate()
  }

  onDisable() {
    this.stopTrackedUpdate()
    this.animations.forEach(a => a.stop())
    this.children.forEach((c, k) => c.disable?.())
  }

  adjustCanvas() {
    super.adjustCanvas()
    this.children.forEach((c, k) => c.adjustCanvas?.())
  }

  setupAnimations() {
    const o_timeDelta = this.coo("fakeTimeDelta")

    const anim = new Animation(12000, {
      // easing: "easeInOutCubic",
    },(a, t, rt, et, now) => {
      o_timeDelta.currentValue = a.tdeltaWas + (t * a.targetSeconds)
      let updatedThisFrame = false

      a.limitFPS(1, _ => {
        this.update()
        updatedThisFrame = true
      })

      !updatedThisFrame && a.limitFPS(10, _ => {
        this.activeSegment.update(this.eye.currentIndex)
      })

      !updatedThisFrame && a.limitFPS(30, _ => {
        if(Math.floor(this.eye.currentIndex.base) != this.currentIndex.base) {
          this.update()
        } else {
          this.eye.update()
        }
      })
    }).on("start", a => {
      a.tdeltaWas = this.co("timeDelta")
      a.mayUseFakeWas = this.stormTimes.mayUseFake
      this.stormTimes.mayUseFake = false
    }).on("ended", a => {
      o_timeDelta.currentValue = null
      this.stormTimes.mayUseFake = a.mayUseFakeWas
      this.update()
    })

    anim.ff = async (hours, msPerHour = 1000) => {
      await this.stormdata.load()
      anim.duration = hours * msPerHour
      anim.targetSeconds = hours * 60 * 60
      anim.start()
    }

    this.animations.set("positionFF", anim)
  }

  startTrackedUpdate() {
    clearInterval(this.trackedUpdateInterval)
    const doAnimate = this.o("animateStorm")
    const updateInterval = doAnimate ? this.o("updateEyeAnimEvery") : this.o("updateEyeEvery")

    this.trackedUpdateInterval = setInterval(_ => {
      if(!this.enabled) return false
      if(this.animating) return false
      if(!this.stormdata.loaded) return false
      if(!this.eye.currentIndex) return false
      const now = Date.now()

      // update storm - animation tick only?
      this.lastEyeUpdate ??= now
      if(!doAnimate || this.o("updateEyeEvery") === 0 || (this.lastEyeUpdate && now - this.lastEyeUpdate >= this.o("updateEyeEvery"))) {
        this.lastEyeUpdate = now

        // update whole path if active segment changes
        if(Math.floor(this.eye.currentIndex.base) != this.currentIndex.base) {
          this.lastActiveSegmentUpdate = now
          this.lastStormTimesUpdate = now
          return this.update()
        } else {
          this.eye.update()
        }
      } else {
        this.eye.animHop().render(true)
      }

      // update active element
      this.lastActiveSegmentUpdate ??= now
      if(this.o("updateActiveSegmentEvery") === 0 || (this.lastActiveSegmentUpdate && now - this.lastActiveSegmentUpdate >= this.o("updateActiveSegmentEvery"))) {
        this.activeSegment.update(this.eye.currentIndex)
        this.lastActiveSegmentUpdate = now
      }

      // update times
      this.lastStormTimesUpdate ??= now
      if(this.o("updateStormTimesEvery") === 0 || (this.lastStormTimesUpdate && now - this.lastStormTimesUpdate >= this.o("updateStormTimesEvery"))) {
        this.stormTimes.update(this.eye.currentIndex)
        this.lastStormTimesUpdate = now
      }
    }, updateInterval)
  }

  stopTrackedUpdate() {
    clearInterval(this.trackedUpdateInterval)
    delete this.trackedUpdateInterval
  }

  update(ev) {
    if(!this.enabled) return false
    if(!this.stormdata.loaded) return false
    const idx = this.prepareStormIndex()
    if(ev) { delete this.eye.previousDot }
    this.children.forEach((c, k) => c.update?.(idx))
    // super.update()
  }

  render() { throw("render called on simulation") }

  get lw() {
    const clampedScale = Math.min(this.o("maxScale") ?? 3, Math.max(1, this.world.vscale))
    const lineWidth = Math.max(this.o("minThickness") ?? 1, this.o("thickness") * clampedScale)
    return lineWidth
  }
}



class StormAddonLayer extends StormLayer {
  init(parent) {
    this.parent = parent
    this.mayUseFake = true
    this.mayUseFakeDelta = true
    this.mayUseFakeIndex = true
    this.mayUseFakeTime = true
    this.autoshowHUD = false
  }

  get stormdata() { return this.parent.stormdata }
  co(...args) { return this.parent.o(...args) }
  coo(...args) { return this.parent.oo(...args) }
  cc(color) { return this.parent.cachedColors.get(color) }

  update(index) {
    if(!this.stormdata.loaded) return false
    this.prepareStormIndex(index)
    super.update()
  }
  render() {}
}



class StormPath extends StormAddonLayer {
  // init(...args) {
  //   super.init(...args)
  //   this.debounceRenderWithFade = 100
  // }

  render() {
    if(!this.enabled) return false
    this.clear()

    for (let i = this.currentIndex.lower; i <= this.currentIndex.upper; i++) {
      if(i != this.currentIndex.base) this.renderSegment(i, null, i == this.currentIndex.upper)
    }
  }

  renderSegment(i, t, isLastSegment = false) {
    const n1 = this.stormdata.atVindex(i)
    const n2 = this.stormdata.atVindex(i + 1)
    const p1 = [n1.px, n1.py]
    const p2 = [n2.px, n2.py]
    const t1 = [n1.tx, n1.ty]
    const t2 = [n2.tx, n2.ty]
    const [c1, c2] = this.calculateControlPoints(p1, p2, [t1[0] * 1000, t1[1] * 1000], [t2[0] * 1000, t2[1] * 1000], this.co("pathSmoothingAlpha"))
    // const c1t = [c1[0] / this.world.realScale, c1[1] / this.world.realScale]
    // const c2t = [c2[0] / this.world.realScale, c2[1] / this.world.realScale]
    const c1t = this.translateGameVirtual(c1)
    const c2t = this.translateGameVirtual(c2)
    const p1t = this.translateGameVirtual(p1)
    const p2t = this.translateGameVirtual(p2)

    const isHistoryTail = i < this.currentIndex.base
    const isBase = i == this.currentIndex.base
    const isFutureTongue = i > this.currentIndex.base
    // const range = this.currentIndex.upper - this.currentIndex.lower
    // const node = this.stormdata.atVindex(i)
    const dist = isHistoryTail ? this.currentIndex.base - i : i - this.currentIndex.base
    const distN = isHistoryTail ? dist / this.co("tail") : isFutureTongue ? dist / this.co("tongue") : 0
    const distC = n1.alpha = Math.min(1, 1.1 - (isHistoryTail ? dist / this.co("tail") : isFutureTongue ? dist / this.co("tongue") : 0.1))
    // console.log(`%c${distN}`, `color: rgb(${this.hsvToRgb(distN, 1, 1).join(",")})`)

    this.trx(ctx => {
      if(isHistoryTail) {
        ctx.strokeStyle = n1.color = `rgba(33, 33, 33, ${distC.toFixed(6)})`
      } else if (isBase) {
        ctx.strokeStyle = n1.color = `rgba(${this.hsvToRgb(1, 1, 1).join(",")}, 1)`
      } else {
        ctx.strokeStyle = n1.color = `rgba(${this.hsvToRgb(distN, 1, 1).join(",")}, ${distC.toFixed(6)})`
      }

      const renderControlPoints = this.co("renderControlPoints")

      if(renderControlPoints > 1) {
        this.drawCircle([c1[0], c1[1]], 10000, { color: "#ff0000ee", scaled: true })
        this.drawCircle([c2[0], c2[1]], 10000, { color: "#0000ffee", scaled: true })
        this.drawLine(p1, c1, { color: "lime" })
        this.drawLine(p2, c2, { color: "lime" })
      }
      if(renderControlPoints > 2) {
        const tx1m = [p1[0] + t1[0] * 1000, p1[1] + t1[1] * 1000]
        const tx2m = [p2[0] + t2[0] * 1000, p2[1] + t2[1] * 1000]
        this.drawX(tx1m, 15000, true, { color: "magenta" })
        this.drawX(tx2m, 15000, true, { color: "black" })
        this.drawLine(p1, tx1m, { color: "black" })
        this.drawLine(p2, tx2m, { color: "black" })

        const tx1 = [p1[0] + t1[0] * 100, p1[1] + t1[1] * 100]
        const tx2 = [p2[0] + t2[0] * 100, p2[1] + t2[1] * 100]
        this.drawX(tx1, 15000, true, { color: "magenta" })
        this.drawX(tx2, 15000, true, { color: "black" })
        this.drawLine(p1, tx1, { color: "magenta" })
        this.drawLine(p2, tx2, { color: "magenta" })
      }

      const paths = []

      if(isBase && this.co("splitActiveSegment")) {
        const [segment1, segment2] = this.splitBezier(p1t, c1t, c2t, p2t, t)
        segment1.unshift(`rgb(33, 33, 33)`)
        segment2.unshift(`rgb(255, 0, 0)`)
        paths.push(segment1)
        paths.push(segment2)

        if(renderControlPoints) {
          this.drawX45(p1, 15000, true, { color: `rgb(33, 33, 33)` })
          if(isLastSegment) this.drawX45(p2, 15000, true, { color: `rgb(255, 0, 0)` })
        }
      } else {
        paths.push([null, p1t, c1t, c2t, p2t])

        if(renderControlPoints) {
          this.drawX45(p1, 15000, true)
          if(isLastSegment) this.drawX45(p2, 15000, true)
        }
      }

      // path
      for (var i = 0; i < paths.length; i++) {
        const p = paths[i]
        if(p[0]) {
          ctx.strokeStyle = p[0]
        }
        ctx.beginPath()
        ctx.moveTo(p[1][0], p[1][1])
        // if(i==165)console.log(p1[0], p1t[0], p1t[1])
        // ctx.setLineDash([10, 5])
        ctx.setLineDash([4, 2, 4, 2, 4, 2, 20, 10])
        ctx.bezierCurveTo(p[2][0], p[2][1], p[3][0], p[3][1], p[4][0], p[4][1])
        ctx.lineWidth = this.parent.lw
        ctx.stroke()
      }
    })
  }
}



class ActiveSegment extends StormPath {
  render() {
    if(!this.enabled) return false
    this.clear()
    this.renderSegment(this.currentIndex.base, this.currentIndex.t, this.currentIndex.base == this.currentIndex.upper)
  }
}



class StormTimes extends StormAddonLayer {
  init(...args) {
    super.init(...args)
    this.textcache = new Map()
    this.coo("timeDelta").onChange(_ => {
      this.textcache.forEach(t => delete t.lastTimeUpdate)
    })
  }

  render() {
    if(!this.enabled) return false
    this.clear()
    if(!this.co("renderStormTimes")) return false

    for (let i = this.currentIndex.lower; i <= this.currentIndex.upper; i++) {
      this.renderSegmentTime(i, this.currentIndex.float)
    }
  }

  formatSeconds(seconds) {
    const secs = Math.abs(Math.round(seconds))
    const neg = seconds < 0
    let str = ``
    if(false) {
    // } else if(secs < 60) {
    //   str = `${secs}s`
    // } else if (secs < 120) {
    //   str = `1m ${secs % 60}s`
    } else if (secs < (60 * 60)) {
      str = `${Math.round(secs / 60)}m`
    } else {
      let m = Math.round(secs / 60)
      const h = Math.floor(m / 60)
      m -= h * 60
      str = `${h}h ${m}m`
    }
    if(!str) return ""
    return neg ? str + " ago" : str
  }

  renderSegmentTime(i, t) {
    let text = this.textcache.get(i)
    if(!text) {
      text = this.createText("aye", { anchor: -0.5 })
      text.updateTime = (seconds) => {
        let doUpdate = false
        const now = performance.now()
        if(!text.lastTimeUpdate) {
          text.lastTimeUpdate = now
          doUpdate = true
        }
        const lastUpdateDiff = now - text.lastTimeUpdate
        const secs = Math.floor(Math.abs(seconds))

        if(!doUpdate && secs < 120) {
          // doUpdate = lastUpdateDiff >= (1000) - 10
          doUpdate = lastUpdateDiff >= (5 * 1000) - 10
        } else if (!doUpdate && secs < (60 * 60)) {
          doUpdate = lastUpdateDiff >= (60 * 1000) - 10
        } else if (!doUpdate) {
          doUpdate = lastUpdateDiff >= (60 * 60 * 1000) - 10
        }

        if(doUpdate) {
          text.text = this.formatSeconds(seconds)
          text.lastTimeUpdate = now
        }
        return text
      }
      this.textcache.set(i, text)
    }
    const n = this.stormdata.atVindex(i)
    const tdelta = i * 1000 - t * 1000
    if(Math.abs(tdelta) <= 30) return
    if(!this.pointIntersectViewport([n.px, n.py])) return
    text.updateTime(tdelta)
    text.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
    text.opts.globalAlpha = n.alpha
    text.draw(n.px, n.py)
  }
}



class StormEye extends StormAddonLayer {
  init(...args) {
    super.init(...args)
    this.animTick = [0, 0, 0]
    this.animTickL = [0, 0, 0]
  }

  onEnable() {
    this.prepareRandomDashLayouts(20, 7, 15, 3, 7)
  }

  adjustCanvas(...args) {
    delete this.previousDot
    super.adjustCanvas(...args)
  }

  prepareRandomDashLayouts(...args) {
    this.lineDashLayouts = [
      this.generateRandomDashLayout(...args),
      this.generateRandomDashLayout(...args),
      this.generateRandomDashLayout(...args),
    ]

    this.animTickL = [
      (this.lineDashLayouts[0].len - 1) * 3,
      (this.lineDashLayouts[1].len - 1) * 2,
      (this.lineDashLayouts[2].len - 1) * 1,
    ]
  }

  update(index, cc) {
    if(!this.stormdata.loaded) return false
    this.prepareStormIndex(index)

    if(cc) {
      this.coordCache = cc
    } else {
      let p1, p2, c1, c2;
      const n1 = this.stormdata.atVindex(this.currentIndex.base)
      const n2 = this.stormdata.atVindex(this.currentIndex.base + 1)
      p1 = [n1.px, n1.py]
      p2 = [n2.px, n2.py]
      const what = this.calculateControlPoints(p1, p2, [n1.tx * 1000, n1.ty * 1000], [n2.tx * 1000, n2.ty * 1000], this.co("pathSmoothingAlpha"))
      c1 = what[0]
      c2 = what[1]
      this.coordCache = [p1, p2, c1, c2]
    }
    if (this.co("animateStorm")) this.animHop()

    super.update(index)
  }

  animHop() {
    for (var i = 0; i < this.animTick.length; i++) {
      if(this.animTick[i] >= this.animTickL[i]) {
        this.animTick[i] = 0
      } else {
        this.animTick[i] += 1
      }
    }
    return this
  }

  render(animTick = false) {
    if(!this.enabled) return false
    this.clear()
    this.renderEye(this.currentIndex.base, this.currentIndex.t, animTick)
  }

  renderEye(base, t, animTick) {
    let [p1, p2, c1, c2] = this.coordCache

    const xDot = (1-t)**3 * p1[0] + 3*(1-t)**2 * t * c1[0] + 3*(1-t) * t**2 * c2[0] + t**3 * p2[0]
    const yDot = (1-t)**3 * p1[1] + 3*(1-t)**2 * t * c1[1] + 3*(1-t) * t**2 * c2[1] + t**3 * p2[1]
    const dot = [xDot, yDot]
    if(this.previousDot && !animTick) {
      this.stormDirection = this.getAngleBetweenPoints(this.previousDot[0], dot)
      const dist = this.distance2d(this.previousDot[0], dot)
      const tdelta = performance.now() - this.previousDot[1]
      this.stormSpeed = {}
      this.stormSpeed.uPerSecond = (dist / tdelta) * 1000
      this.stormSpeed.mPerSecond = this.stormSpeed.uPerSecond / 100
      this.stormSpeed.kmPerHour = this.stormSpeed.mPerSecond * 3.6
    }

    this.trx(ctx => {
      ctx.globalAlpha = this.co("eyeAlpha")

      this.drawCircle(dot, 5000, { fill: this.cc("stormCenter") }) // storm center dot

      // center dot direction arrow
      if(this.stormDirection && this.co("renderDirectionArrow")) {
        this.drawPath(this.createArrowPaths(xDot, yDot, 5000, this.stormDirection), { fill: this.cc("stormArrow") })
      }

      ctx.setLineDash(this.lineDashLayouts[0].seq)
      ctx.lineDashOffset = this.animTick[0] / 3
      this.drawCircle(dot, 140000, { color: this.cc("stormOuter"), lw: 2 }) // outer radius

      ctx.setLineDash(this.lineDashLayouts[1].seq)
      ctx.lineDashOffset = this.animTick[1] / 2
      this.drawCircle(dot, 90000, { color: this.cc("stormRadius"), lw: 2 }) // radius

      ctx.setLineDash(this.lineDashLayouts[2].seq)
      ctx.lineDashOffset = this.animTick[2] / 1
      this.drawCircle(dot, 63000, { color: this.cc("stormInner"), lw: 2 }) // wind_inner

      if(this.co("renderProgressText")) {
        const prec = this.co("renderProgressPrecision")
        this.detailText ??= this.createText("aye", { offsetY: -5, anchor: [-0.5, -1] })
        this.detailText.opts.font = `${this.zoomVec(8, 1.5, 5)}px windlass-extended`
        this.detailText.text = `${(prec === 0 ? Math.floor(t * 100) : (t * 100)).toFixed(prec)}%`
        this.detailText.draw(xDot, yDot - 5000)
      }
    })

    if(animTick) return
    if(this.previousDot) {
      this.previousDot[0] = dot
      this.previousDot[1] = performance.now()
    } else {
      this.previousDot ??= [dot, performance.now()]
    }
  }
}
