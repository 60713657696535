import { SimpleEvents } from "../../../appos/simple_events"
import { ExternalPromise } from "../../../appos/lib/external_promise"

export class Animation {
  constructor(duration, ...args) {
    SimpleEvents.applyOn(this, ["start", "stop", "tick", "complete", "ended"])
    this.opts = {
      easing: "linear",
      fireTicks: false,
    }

    if(args.length == 2) {
      this.opts = Object.assign({}, this.opts, args[0])
      this.animator = args[1]
    } else {
      this.animator = args[0]
    }

    this.everyNframe = new Map()
    this.promise = new ExternalPromise()
    this.duration = duration
    this.running = false
    this.startTime = null
    this.tickN = 0
    this.requestId = null
  }

  getNow() { return window.performance.now() }

  restart() { return this.stop().start() }

  start() {
    if(this.requestId) return this
    this.startTime = this.getNow()
    this.tickN = 0
    this.running = true
    this.requestId = requestAnimationFrame(this.tick.bind(this))
    this.fire("start", this)
    return this
  }

  stop(finalize = false) {
    if(!this.requestId) return this
    cancelAnimationFrame(this.requestId)
    this.requestId = null
    this.running = false
    if(finalize) this.animate(1, 1, this.duration, this.getNow())
    this.promise.resolve(this)
    this.fire("stop", this)
    this.fire("ended", this)
    return this
  }

  limitFPS(fps, cb) {
    this.limit(`fps_${fps}`, 1000 / fps, cb)
  }

  limit(name, ms, cb) {
    let then = this.everyNframe.get(name)
    if(!then) {
      then = this.startTime //[ms, , cb]
      this.everyNframe.set(name, then)
    }
    const elapsed = this.now - then
    if(elapsed > ms) {
      cb(elapsed)
      this.everyNframe.set(name, this.now - (elapsed % ms))
    }
  }

  define(name, fn) {
    this[name] = (...args) => { fn(this, ...args) }
    return this
  }

  getEasing(e) { return Easings[e] }

  ease(t) {
    if(this.opts.easingFunction) {
      return this.opts.easingFunction(t)
    } else {
      return Easings[this.opts.easing](t)
    }
  }

  elapsedTime(now) { return this.startTime ? (now ?? this.now) - this.startTime : 0 }

  tick(now) {
    this.now = now
    const elapsedTime = this.elapsedTime()
    const t = Math.min(elapsedTime / this.duration, 1)
    const easedT = this.ease(t)

    this.animate(easedT, t, elapsedTime, now)
    if(this.opts.fireTicks) this.fire("tick", easedT, t, elapsedTime, now, this)

    if (elapsedTime < this.duration) {
      this.requestId = requestAnimationFrame(this.tick.bind(this))
    } else {
      this.promise.resolve(this)
      this.fire("complete", this)
      this.fire("ended", this)
      this.requestId = null
      this.running = false
    }

    this.tickN++
  }

  animate(t, rt, et, now) { this.animator?.(this, t, rt, et, now) }
}

export class Easings {
  // Based on https://raw.githubusercontent.com/AndrewRayCode/easing-utils/master/src/easing.js
  // Based on https://gist.github.com/gre/1650294

  // No easing, no acceleration
  static linear( t ) {
    return t;
  }

  // Slight acceleration from zero to full speed
  static easeInSine( t ) {
    return -1 * Math.cos( t * ( Math.PI / 2 ) ) + 1;
  }

  // Slight deceleration at the end
  static easeOutSine( t ) {
    return Math.sin( t * ( Math.PI / 2 ) );
  }

  // Slight acceleration at beginning and slight deceleration at end
  static easeInOutSine( t ) {
    return -0.5 * ( Math.cos( Math.PI * t ) - 1 );
  }

  // Accelerating from zero velocity
  static easeInQuad( t ) {
    return t * t;
  }

  // Decelerating to zero velocity
  static easeOutQuad( t ) {
    return t * ( 2 - t );
  }

  // Acceleration until halfway, then deceleration
  static easeInOutQuad( t ) {
    return t < 0.5 ? 2 * t * t : - 1 + ( 4 - 2 * t ) * t;
  }

  // Accelerating from zero velocity
  static easeInCubic( t ) {
    return t * t * t;
  }

  // Decelerating to zero velocity
  static easeOutCubic( t ) {
    const t1 = t - 1;
    return t1 * t1 * t1 + 1;
  }

  // Acceleration until halfway, then deceleration
  static easeInOutCubic( t ) {
    return t < 0.5 ? 4 * t * t * t : ( t - 1 ) * ( 2 * t - 2 ) * ( 2 * t - 2 ) + 1;
  }

  // Accelerating from zero velocity
  static easeInQuart( t ) {
    return t * t * t * t;
  }

  // Decelerating to zero velocity
  static easeOutQuart( t ) {
    const t1 = t - 1;
    return 1 - t1 * t1 * t1 * t1;
  }

  // Acceleration until halfway, then deceleration
  static easeInOutQuart( t ) {
    const t1 = t - 1;
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * t1 * t1 * t1 * t1;
  }

  // Accelerating from zero velocity
  static easeInQuint( t ) {
    return t * t * t * t * t;
  }

  // Decelerating to zero velocity
  static easeOutQuint( t ) {
    const t1 = t - 1;
    return 1 + t1 * t1 * t1 * t1 * t1;
  }

  // Acceleration until halfway, then deceleration
  static easeInOutQuint( t ) {
    const t1 = t - 1;
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * t1 * t1 * t1 * t1 * t1;
  }

  // Accelerate exponentially until finish
  static easeInExpo( t ) {
    if( t === 0 ) return 0;
    return Math.pow( 2, 10 * ( t - 1 ) );
  }

  // Initial exponential acceleration slowing to stop
  static easeOutExpo( t ) {
    if( t === 1 ) return 1;
    return ( -Math.pow( 2, -10 * t ) + 1 );
  }

  // Exponential acceleration and deceleration
  static easeInOutExpo( t ) {
    if( t === 0 || t === 1 ) return t;
    const scaledTime = t * 2;
    const scaledTime1 = scaledTime - 1;
    if( scaledTime < 1 ) {
      return 0.5 * Math.pow( 2, 10 * ( scaledTime1 ) );
    }
    return 0.5 * ( -Math.pow( 2, -10 * scaledTime1 ) + 2 );
  }

  // Increasing velocity until stop
  static easeInCirc( t ) {
    const scaledTime = t / 1;
    return -1 * ( Math.sqrt( 1 - scaledTime * t ) - 1 );
  }

  // Start fast, decreasing velocity until stop
  static easeOutCirc( t ) {
    const t1 = t - 1;
    return Math.sqrt( 1 - t1 * t1 );
  }

  // Fast increase in velocity, fast decrease in velocity
  static easeInOutCirc( t ) {
    const scaledTime = t * 2;
    const scaledTime1 = scaledTime - 2;

    if( scaledTime < 1 ) {
      return -0.5 * ( Math.sqrt( 1 - scaledTime * scaledTime ) - 1 );
    }

    return 0.5 * ( Math.sqrt( 1 - scaledTime1 * scaledTime1 ) + 1 );
  }

  // Slow movement backwards then fast snap to finish
  static easeInBack( t, magnitude = 1.70158 ) {
    return t * t * ( ( magnitude + 1 ) * t - magnitude );
  }

  // Fast snap to backwards point then slow resolve to finish
  static easeOutBack( t, magnitude = 1.70158 ) {
    const scaledTime = ( t / 1 ) - 1;
    return (
      scaledTime * scaledTime * ( ( magnitude + 1 ) * scaledTime + magnitude )
    ) + 1;
  }

  // Slow movement backwards, fast snap to past finish, slow resolve to finish
  static easeInOutBack( t, magnitude = 1.70158 ) {
    const scaledTime = t * 2;
    const scaledTime2 = scaledTime - 2;
    const s = magnitude * 1.525;

    if( scaledTime < 1) {
      return 0.5 * scaledTime * scaledTime * (
        ( ( s + 1 ) * scaledTime ) - s
      );
    }

    return 0.5 * (
      scaledTime2 * scaledTime2 * ( ( s + 1 ) * scaledTime2 + s ) + 2
    );
  }

  // Bounces slowly then quickly to finish
  static easeInElastic( t, magnitude = 0.7 ) {
    if( t === 0 || t === 1 ) return t;

    const scaledTime = t / 1;
    const scaledTime1 = scaledTime - 1;

    const p = 1 - magnitude;
    const s = p / ( 2 * Math.PI ) * Math.asin( 1 );

    return -(
      Math.pow( 2, 10 * scaledTime1 ) *
      Math.sin( ( scaledTime1 - s ) * ( 2 * Math.PI ) / p )
    );
  }

  // Fast acceleration, bounces to zero
  static easeOutElastic( t, magnitude = 0.7 ) {
    if( t === 0 || t === 1 ) return t;
    const p = 1 - magnitude;
    const scaledTime = t * 2;

    const s = p / ( 2 * Math.PI ) * Math.asin( 1 );
    return (
      Math.pow( 2, -10 * scaledTime ) *
      Math.sin( ( scaledTime - s ) * ( 2 * Math.PI ) / p )
    ) + 1;
  }

  // Slow start and end, two bounces sandwich a fast motion
  static easeInOutElastic( t, magnitude = 0.65 ) {
    if( t === 0 || t === 1 ) return t;
    const p = 1 - magnitude;
    const scaledTime = t * 2;
    const scaledTime1 = scaledTime - 1;
    const s = p / ( 2 * Math.PI ) * Math.asin( 1 );

    if( scaledTime < 1 ) {
      return -0.5 * (
        Math.pow( 2, 10 * scaledTime1 ) *
        Math.sin( ( scaledTime1 - s ) * ( 2 * Math.PI ) / p )
      );
    }

    return (
      Math.pow( 2, -10 * scaledTime1 ) *
      Math.sin( ( scaledTime1 - s ) * ( 2 * Math.PI ) / p ) * 0.5
    ) + 1;
  }

  // Bounce to completion
  static easeOutBounce( t ) {
    const scaledTime = t / 1;
    if( scaledTime < ( 1 / 2.75 ) ) {
      return 7.5625 * scaledTime * scaledTime;
    } else if( scaledTime < ( 2 / 2.75 ) ) {
      const scaledTime2 = scaledTime - ( 1.5 / 2.75 );
      return ( 7.5625 * scaledTime2 * scaledTime2 ) + 0.75;
    } else if( scaledTime < ( 2.5 / 2.75 ) ) {
      const scaledTime2 = scaledTime - ( 2.25 / 2.75 );
      return ( 7.5625 * scaledTime2 * scaledTime2 ) + 0.9375;
    } else {
      const scaledTime2 = scaledTime - ( 2.625 / 2.75 );
      return ( 7.5625 * scaledTime2 * scaledTime2 ) + 0.984375;
    }
  }

  // Bounce increasing in velocity until completion
  static easeInBounce( t ) {
    return 1 - this.easeOutBounce( 1 - t );
  }

  // Bounce in and bounce out
  static easeInOutBounce( t ) {
    if( t < 0.5 ) {
      return this.easeInBounce( t * 2 ) * 0.5;
    }

    return ( this.easeOutBounce( ( t * 2 ) - 1 ) * 0.5 ) + 0.5;
  }
}
