import * as AppOS from "../appos"

const Component = class extends AppOS.Component {
  static name = "SotPlayer"

  documentLoad() {
    // $(document).keydown(ev => {
    //   if(!this.world) return
    //   return this.world.handleKeydown?.(ev)
    // })
    // $(document).keyup(ev => {
    //   if(!this.world) return
    //   return this.world.handleKeyup?.(ev)
    // })
  }

  pageLoad() {
    const player = document.querySelector("#sot-player")
    if(!player) return
    window.sotplayer = this.player = new Player(player)

    this.app.input?.onKeydownThisPage(ev => {
      if (ev.keyCode == 32) {
        this.player.currentTrack?.toggle()
        ev.preventDefault()
      } else if (ev.keyCode == 38) {
        this.player.volume = Math.min(100, this.player.volume + 5)
        ev.preventDefault()
      } else if (ev.keyCode == 40) {
        this.player.volume = Math.max(0, this.player.volume - 5)
        ev.preventDefault()
      } else {
        // console.log(ev.keyCode)
      }
    })
  }
}




class Track {
  constructor(name, length = 0.0, opts = {}) {
    this.name = name
    this.bits = []
    this.uniqDuration = 0
    this.bufferSize = 0
    this.bufferByteSize = 0
    this.volumeDec = 69
    this.opts = Object.assign({}, {
      length: length,
    }, opts)
  }

  get id() {
    return this.name
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with a dash
      .replace(/^-+|-+$/g, "")     // Remove leading/trailing dashes
  }

  get bitCount() {
    let n = 0
    this.bits.forEach(bit => n += Array.isArray(bit.n) ? bit.n.length : 1)
    return n
  }
  get duration() { return this.uniqDuration == 0 ? this.opts.length ?? 0 : this.uniqDuration }

  start(bit) {
    this.bits.push({ n: bit, duration: 0.0, length: 0, buffer: null })
    return this
  }

  then(bit) {
    this.bits.push({ n: bit, duration: 0.0, length: 0, buffer: null })
    return this
  }

  loop(bit) {
    this.bits.push({ n: bit, duration: 0.0, length: 0, buffer: null, loop: true })
    return this
  }

  get volume() { return this.volumeDec }

  set volume(v) {
    if(this.gainNode) this.gainNode.gain.value = v / 100
    return this.volumeDec = v
  }

  load() {
    if(this.ready) return this.ready
    this.setStatus("loading")

    this.loadAborter = new AbortController()
    this.newActx()

    const colors = ["primary", "success", "info", "warning", "danger", "secondary", "light"]
    const promises = this.bits.map(bit => this.fetchBit(bit))
    Promise.all(promises).then(_ => {
      this.setStatus(null)
      this.setV("progress-bar", el => {
        el.innerHTML = ""
        this.bits.forEach((bit, i) => {
          const perc = bit.duration / this.duration * 100
          $(`<div class="progress-bar bg-${colors[i]}" style="width: ${perc}%"></div>`).appendTo($(el))
        })
      })
      this.setV("track-length", this.formatSeconds(this.duration))
      this.setV("track-memory", `~&thinsp;${(this.bufferByteSize / 1024 / 1024).toFixed(0)}&thinsp;MB`)
      this.dom.dataset.duration = this.duration.toFixed(3)
    }).catch(err => {
      console.error(err)
      this.setStatus("error")
    })
    return this.ready = Promise.all(promises)
  }

  newActx() {
    this.actx = new (AudioContext || webkitAudioContext)()
    this.gainNode = this.actx.createGain()
    this.gainNode.gain.value = (this.volume ?? 69) / 100
    this.gainNode.connect(this.actx.destination)
    return this
  }

  free() {
    //console.log(this.name, "freeing")
    if(!this.ready) return
    this.stop()
    this.setStatus(null)
    this.uniqDuration = 0
    this.bufferSize = 0
    this.bufferByteSize = 0
    this.loadAborter?.abort?.()
    this.activeNodes?.forEach(n => n.stop())
    this.actx?.close()
    this.bits.forEach(bit => bit.buffer = null)
    delete this.ready
    delete this.activeNodes
    delete this.actx
  }

  play(restart = true, seek = 0) {
    this.load().then(buffers => {
      console.log(this.name, "playing", "seek", seek, "uniqDuration:", this.uniqDuration.toFixed(3), this.formatSeconds(this.uniqDuration), "bufferSize:", this.bufferSize, "bufferByteSize:", this.bufferByteSize, this.bits)
      this.activeNodes ??= []
      if((restart || seek)) { this.activeNodes.forEach(n => n.stop()); this.activeNodes = [] }

      this.setStatus("playing")
      this.startUI()

      if(this.actx.state == "suspended") {
        // console.log("resuming context")
        this.actx.resume()
      }

      this.startedAt = this.actx.currentTime - seek
      let ptr = this.actx.currentTime
      let rptr = 0
      this.bits.forEach((bit, bi) => {
        if(seek <= rptr + bit.duration) {
          let delta = seek - rptr
          // console.log(bi, "seek", seek, "rptr", rptr, "delta", delta, "ptr", ptr, "dur", bit.duration)
          if(delta < 0) delta = 0
          // console.log(seek, rptr, ptr)

          const buffers = Array.isArray(bit.n) ? bit.buffer : [bit.buffer]
          buffers.forEach((buf, bi) => {
            const node = this.actx.createBufferSource()
            node.buffer = buf
            if(bit.loop) { node.loop = true }
            if(bit.loopEnd && bit.loopEnd != bit.duration) { node.loopEnd = bit.loopEnd }
            node.connect(this.gainNode)
            node.start(ptr, delta)
            this.activeNodes.push(node)
          })

          ptr += bit.duration - delta
          rptr += bit.duration - delta
        } else {
          rptr += bit.duration
          // console.log("skipping bit", bi)
        }
      })
    })

    return this
  }

  get suspended() { return this.actx?.state == "suspended" }

  stop() {
    if(this.suspended) return
    //console.log(this.name, "stopping")
    this.setStatus("stopped")
    this.stopUI()
    this.actx.suspend()
    return this
  }

  resume() {
    if(!this.suspended) return
    //console.log(this.name, "resuming")
    this.actx.resume()
    this.startUI()
    this.setStatus("playing")
    return this
  }

  toggle() {
    return this.suspended ? this.resume() : this.stop()
  }

  startUI() {
    clearInterval(this.uiTimer)
    this.uiTimer = setInterval(_ => { this.updateUI() }, 500)
    this.updateUI()
  }

  updateUI() {
    let cTime = this.actx.currentTime - (this.startedAt ?? 0)
    this.setV("track-currentTime", this.formatSeconds(cTime, this.duration > 60, this.duration > 3600))

    let rTime = cTime
    if(rTime > this.duration) {
      const lastBit = this.bits[this.bits.length - 1]
      const nonLoopingDuration = this.duration - lastBit.duration
      rTime = nonLoopingDuration + ((rTime - nonLoopingDuration)  % lastBit.duration)
    }
    this.setV("progress-bar-indicator", el => el.style.left = `${rTime / this.duration * 100}%`)
  }

  stopUI() {
    clearInterval(this.uiTimer)
    delete this.uiTimer
  }

  fetchBit(bit, sbit) {
    if(Array.isArray(bit.n)) {
      bit.buffer = []
      bit.duration = 0
      bit.durations = []
      bit.length = 0
      bit.bytesize = 0
      const promises = []
      bit.n.forEach((an, ai) => {
        promises.push(this.fetchAudioAsBuffer(`https://soundtag.geekya.com/blob/${an}.ogg`).then(buf => {
          bit.buffer[ai] = buf
          bit.durations[ai] = buf.duration
          bit.length += buf.length
          bit.bytesize += buf.numberOfChannels * buf.sampleRate * buf.duration * 4 // 4 bytes per sample 32-bit floating point
          this.bufferSize += bit.length
          this.bufferByteSize += bit.bytesize
          return bit
        }))
      })
      Promise.all(promises).then(bufs => {
        const minDur = Math.min(...bit.durations)
        bit.duration = bit.loopEnd = minDur
        this.uniqDuration += minDur
      })
      return Promise.all(promises)
    } else {
      return this.fetchAudioAsBuffer(`https://soundtag.geekya.com/blob/${bit.n}.ogg`).then(buf => {
        bit.buffer = buf
        bit.duration = buf.duration
        bit.length = buf.length
        bit.bytesize = buf.numberOfChannels * buf.sampleRate * buf.duration * 4 // 4 bytes per sample 32-bit floating point
        this.uniqDuration += bit.duration
        this.bufferSize += bit.length
        this.bufferByteSize += bit.bytesize
        return bit
      })
    }
  }

  fetchAudioAsBuffer(url) {
    return new Promise((resolve, reject) => {
      fetch(url, { mode: "cors", signal: this.loadAborter?.signal })
        .then(resp => resp.arrayBuffer())
        .then(b => this.actx.decodeAudioData(b, buf => {
          resolve(buf)
        }
      ))
    })
  }

  setStatus(newStatus) {
    $(this.dom).find(`.track-status`).addClass("d-none")
    if(newStatus) $(this.dom).find(`[data-v~="status-${newStatus}"]`).removeClass("d-none")
    return this
  }

  setV(k, v, t = this.dom) {
    t.querySelectorAll(`[data-v="${k}"`).forEach(el => {
      if(!el) return false
      if(typeof v == "function") {
        return v(el)
      } else if (el.innerHTML != v) {
        return el.innerHTML = v
      }
    })
  }

  formatSeconds(tdelta, forceMinutes = false, forceHours = false) {
    let float = tdelta
    let hours = Math.floor(float / 3600)
    float %= 3600
    let minutes = Math.floor(float / 60)
    float %= 60
    let secs = Math.floor(float)
    let millis = Math.round((float - secs) * 1000)
    if(millis == 1000) millis = 999

    let secondsStr = secs.toString().padStart(2, '0')
    let millisStr = millis.toString().padStart(3, '0')

    let formattedTime = `${secondsStr}.${millisStr}`

    if (minutes > 0 || hours > 0 || forceMinutes) {
      let minutesStr = minutes.toString().padStart(2, '0')
      formattedTime = `${minutesStr}:${formattedTime}`
    }

    if (hours > 0 || forceHours) {
      let hoursStr = hours.toString().padStart(2, '0')
      formattedTime = `${hoursStr}:${formattedTime}`
    }

    return formattedTime
  }

  createDom(target) {
    this.dom = document.createElement("div")
    this.dom.setAttribute("data-v", "playlist-entry[]")
    this.dom.setAttribute("data-id", this.id)
    this.dom.setAttribute("data-track", this.name)
    this.dom.dataset.duration = this.duration
    this.dom.innerHTML = `
      <div>
        <div>
          <a href="#play" data-a="track-play" class="ms-1">
            <i class="bi bi-play pe-1" style="white-space: nowrap"></i><span data-v="track-name"></span>
          </a>
        </div>
        <div class="text-muted text-small ms-4 d-flex">
          <div>
            (<span data-v="track-bitCount"></span>)
            <span data-v="track-length"></span>
            <span class="track-status d-none text-info" data-v="status-loading">loading</span>
            <span class="track-status d-none" data-v="status-playing status-stopped">
              <span class="text-success track-status" data-v="status-playing">playing</span>
              <span class="text-warning track-status" data-v="status-stopped">paused</span>
              <span class="text-muted text-small font-monospace">
                <span data-v="track-currentTime"></span>/<span data-v="track-length"></span>
              </span>
            </span>
            <span class="track-status d-none text-danger" data-v="status-error">error</span>
          </div>
          <div class="d-none track-status" data-v="status-stopped">
            <a href="#unload" class="text-danger ms-2" data-a="track-free"><i class="bi bi-box-arrow-down pe-1" style="white-space: nowrap"></i>unload from memory</a>
            (<span data-v="track-memory"></span>)
          </div>
          <div class="flex-grow-1 px-2 d-none track-status" data-v="status-playing">
            <div class="position-relative">
              <div class="progress cursor-text" data-v="progress-bar" data-a="track-seek" style="height: 20px"></div>
              <div class="position-absolute no-pointer-events" data-v="progress-bar-indicator" style="left: ${0}%; top: -8px; font-size: 16px; transform: translate(-50%, 0); transition: left 500ms linear"><i class="bi bi-caret-down-fill text-white" style="white-space: nowrap"></i></div>
            </div>
          </div>
        </div>
      </div>
    `
    this.setV("track-id", this.id)
    this.setV("track-name", this.name)
    this.setV("track-length", this.formatSeconds(this.duration))
    this.setV("track-bitCount", `${this.bitCount} bit${this.bitCount == 1 ? "" : "s"}`)

    return target ? target.append(this.dom) : this.dom
  }
}



class Player {
  constructor(dom) {
    this.dom = dom
    this.v_playlist = dom.querySelector(`[data-v="playlist"]`)
    this.v_playlistEntries = dom.querySelector(`[data-v="playlist-entries"]`)
    this.v_volumeSlider = dom.querySelector(`[data-v="volume-slider"]`)
    this.i_plIndex = new Map()
    this.allBlobs = new Set()

    this.playlist.forEach(track => {
      if(this.dom.dataset.single && !track.id.startsWith(this.dom.dataset.single)) return
      this.i_plIndex.set(track.name, track)
      this.v_playlistEntries.append(track.createDom())

      // track.bits.forEach(bit => {
      //   if(Array.isArray(bit.n)) {
      //     bit.n.forEach(n => this.allBlobs.add(n))
      //   } else {
      //     this.allBlobs.add(bit.n)
      //   }
      // })
    })
    // console.log(this.allBlobs.values().toArray().join(" "))

    this.updateUI()
    this.hook()
  }

  hook() {
    this.v_volumeSlider.addEventListener("change", ev => {
      this.volume = parseInt(this.v_volumeSlider.value)
    })

    this.dom.addEventListener("click", ev => {
      let targetElement = null

      if((targetElement = ev.target.closest(`[data-a="track-play"]`)) && this.dom.contains(targetElement)) {
        ev.stopPropagation()
        ev.preventDefault()

        const outer = $(targetElement).closest(`[data-track]`)
        const track = this.i_plIndex.get(outer.data("track"))

        if(track) {
          if(track == this.currentTrack) {
            this.currentTrack.toggle()
          } else {
            this.currentTrack?.stop()
            window.currentTrack = this.currentTrack = track
            if(ev.shiftKey) {
              track.play(true)
            } else if(track.suspended) {
              track.resume()
            } else {
              track.play()
            }
          }
        } else {
          outer.find(`[data-v="status-error"]`).removeClass("d-none")
        }
      } else if((targetElement = ev.target.closest(`[data-a="track-free"]`)) && this.dom.contains(targetElement)) {
        ev.stopPropagation()
        ev.preventDefault()

        if(ev.shiftKey) {
          $(this.dom).find(`[data-a="track-free"]:visible`).each((i, el) => {
            const outer = $(el).closest(`[data-track]`)
            const track = this.i_plIndex.get(outer.data("track"))
            if(track == this.currentTrack) window.currentTrack = this.currentTrack = null
            track.free()
          })
        } else {
          const outer = $(targetElement).closest(`[data-track]`)
          const track = this.i_plIndex.get(outer.data("track"))
          if(track == this.currentTrack) window.currentTrack = this.currentTrack = null
          track.free()
        }
      } else if($("#sot-player").data("env") == "development" && (targetElement = ev.target.closest(`[data-v="track-length"]`)) && this.dom.contains(targetElement)) {
        const outer = $(targetElement).closest(`[data-track]`)
        const track = this.i_plIndex.get(outer.data("track"))
        navigator.clipboard.writeText(track.dom.dataset.duration)
        targetElement.classList.add("text-warning")
        setTimeout(_ => targetElement.classList.remove("text-warning"), 2000)
      } else if((targetElement = ev.target.closest(`[data-a="track-seek"]`)) && this.dom.contains(targetElement)) {
        ev.stopPropagation()
        ev.preventDefault()

        const seekTo = (ev.clientX - $(targetElement).offset().left) / $(targetElement).outerWidth()
        const outer = $(targetElement).closest(`[data-track]`)
        const track = this.i_plIndex.get(outer.data("track"))
        // console.log(ev, ev.target, seekTo * 100, seekTo * track.duration)
        track.play(true, seekTo * track.duration)
      } else {
        // console.log("?", ev.target)
      }
    })
  }

  setV(k, v, t = this.dom) {
    const el = t.querySelector(`[data-v="${k}"`)
    if(!el) return false
    if(typeof v == "function") {
      return v(el)
    } else if (el.innerHTML != v) {
      return el.innerHTML = v
    }
  }

  updateUI() {
    const i = this.i_plIndex.size
    this.setV("playlist-total", `${i} track${i != 1 ? "s" : ""}`)
    return this
  }

  get playlist() { return this.constructor.PLAYLIST }

  get volume() { return this.setV("volume-slider", el => parseInt(el.value)) }

  set volume(v) {
    this.setV("volume-slider", el => el.value = v)
    this.setV("volume", v.toString().padStart(3, " ").replace(" ", "&nbsp;"))
    this.playlist.forEach(track => track.volume = v)
  }

  // Sublime Selector: (?<=(loop|then|tart)\()([\d]+)(?=\))
  static PLAYLIST = [
    new Track("Server Migration", 52.693).start(459955929).loop(233295000),

    new Track("Menu: Season 06 - Sea Forts", 99.388).loop(584329783),
    new Track("Menu: Season 07 - Sovereigns Theme", 401.975).loop(24157532),
    new Track("Menu: Season 08 - Hourglass", 233.155).start(707145740).loop(519334651),
    new Track("Menu: Season 09 - Fortune Awaits", 187.803).start(698080009).loop(94202830),
    new Track("Menu: Season 10 - Monkey Island", 84.067).start(521069589).loop(884932706),
    new Track("Menu: Season 10 - Guild Theme", 355.533).start(274853767).loop(370976083),
    new Track("Menu: Season 11 - Safer Seas", 170.019).start(286128585).loop(844706404),
    new Track("Menu: Season 12 - War Chest Champion", 226.256).start(246631636).loop(412448514),
    new Track("Menu: Season 13 - Flameheart's Return", 211.445).start(443967771).loop(550253552),
    new Track("Menu: Season 14 - Pirates of Mischief", 157.428).start(471551065).loop(1026617079),
    new Track("Menu: Season 15 - Wild Things", 152.461).start(379476076).loop(86834407),


    new Track("Ghost Ship Combat - Approach", 74.245).start(997935621).loop(299141772),
    new Track("Ghost Ship Combat - Level 1", 380.595).start(7829099).loop(1056797523),
    new Track("Ghost Ship Combat - Level 2", 272.013).start(877787037).loop(954719642),
    new Track("Ghost Ship Combat - Level 3", 301.667).start(270260086).loop(895442220),
    new Track("Ghost Ship Combat - Flameheart", 316.288).start(1008572143).loop(895442220),

    new Track("Burning Bade Battle - Initial", 559.589).start(767381015).loop(798944736),
    new Track("Burning Bade Battle - Subsequent", 473.325).start(158699921).loop(798944736),

    new Track("Ashen Lord - Approach", 211.869).start(950903095).loop(989832693),
    new Track("Ashen Lord - Level 1", 160.261).start(572091218).loop(387911499),
    new Track("Ashen Lord - Level 2", 242.485).start(813499824).loop(754942426),
    new Track("Ashen Lord - Level 3", 289.699).start(354350272).loop(866367593),

    new Track("Fort Anticipation - Level 1", 230.420).loop(423724374),
    new Track("Fort Anticipation - Level 2", 230.420).loop(859793631),
    new Track("Fort Anticipation - Level 3", 230.407).loop(487485223),
    new Track("Fort Anticipation - Level 4", 230.412).loop(1009439994),

    new Track("Fort of the Damned - Waves", 513.765).start(937656057).loop(1028517982),
    new Track("Fort of the Damned - Boss", 159.539).start(990795366).loop(346925365),


    new Track("Skeleton Camp", 225.008).start(977674665).loop(741901594),
    new Track("Constellation Chamber", 174.939).start(572711424).loop(233998763),
    new Track("Skeleton Ship Encounter", 153.600).loop(255789819),
    new Track("The Shrouded Ghost", 143.125).start(541547277).loop(683253212),
    new Track("Treasuries - Boss Wave", 297.469).start(272369363).loop(1011316149),
    new Track("Dark Tides - Soul Flame Captain", 268.312).start(33246730).loop(29349998),

    new Track("Ferry of the Damned", 294.017).loop(45296587),

    new Track("Mood: Sitting", 186.500).loop(171438988),

    new Track("Athena Blessing", 227.449).loop(609246929),
    new Track("Skeleton Ritual", 232.129).loop(142189845),

    new Track("HG: Athena Crescendo", 220.361).loop(345326446),
    new Track("HG: Reapers Crescendo", 220.247).loop(762015131),

    new Track("TT0: Ship Repair", 174.012).loop(994401235),

    new Track("TT2: Catacombs", 228.900).loop(951925730),
    new Track("TT5: Rooke Anticipate", 260.611).start(886337393).loop(697579226),
    new Track("TT5: Rooke Fight", 241.221).start(381957285).loop(538288454),
    new Track("PotC-TT1: Intro", 222.356).loop(998633176),
    new Track("PotC-TT1: Grotto Smugglers Cave", 184.007).loop(273807815),
    new Track("PotC-TT2: Intro", 193.191).loop(973559105),
    new Track("PotC-TT2: Investigate", 216.240).start(727210798).loop(186505722),
    new Track("PotC-TT2: Kraken Battle", 225.001).loop(462127046),
    new Track("PotC-TT2: Siren Queen Battle", 245.787).start(439652603).loop(457915707),
    new Track("PotC-TT2: Siren Queen Who Shall Not Be Returning", 288.431).loop(161244032),
    new Track("PotC-TT3: Ghost Ship Attack", 208.131).start(725246845).loop(857700701),
    new Track("PotC-TT4: Tia Dalma", 281.625).loop(1016966158),
    new Track("PotC-TT4: Chamber Battle", 822.000).start(584287801).then(103698530).then(894174944).then(809268303).loop(535916821),
    new Track("PotC-TT4: Gold Hoarder Fight", 192.605).start(336157274).loop(726672454),
    new Track("PotC-TT4: End Battle Wave", 182.029).start(361042439).loop(386411306),
    new Track("PotC-TT5: Intro", 229.511).loop(775470570),
    new Track("PotC-TT5: Ghost Armada Wave 1", 371.919).start(336537997).then(432422743).loop(476259171),
    new Track("PotC-TT5: Ghost Armada Wave 2", 415.635).start(280220566).loop(402600303),
    new Track("PotC-TT5: Ghost Armada Wave 3", 227.296).start(687521829).loop(423892315),
    new Track("PotC-TT5: Ghost Armada Wave 4", 395.643).start(52111765).then(866694243).loop(415722742),
    new Track("PotC-TT5: Ghost Armada Wave 5", 108.464).start(453440324).loop(505433355),
    new Track("PotC-TT5: Ferryman Speech", 265.825).loop(33169514),
    new Track("Mojo-TT1: Intro", 181.591).loop(84081379),
    new Track("Mojo-TT1: Tavern", 89.672).loop([66587311, 1015444323, 722944910, 877667458]),
    new Track("Mojo-TT2: Mansion", 234.683).start(94019000).loop(824955238),
    new Track("Mojo-TT2: Voodoo Lady", 314.012).loop(1012044521),
    new Track("Mojo-TT2: BattleBants 1-4", 996.320).start(918591341).then(792715134).then(14570913).loop(179487287),
    new Track("Mojo-TT3: Mad Monkey Arrives", 173.167).loop(1046523374),
    new Track("Mojo-TT3: Lava Pursuit Simmer", 209.173).start(442149861).loop(933070714),
    new Track("Mojo-TT3: Lava Ship Battle", 216.949).start(936525022).loop(684336335),
    new Track("Mojo-TT3: Wedding", 240.023).loop(624693688),
    new Track("Mojo-TT3: Finale", 166.265).loop(747616468),

    new Track("Adventure 5: Finale", 147.020).loop(994717959),
    new Track("Adventure 6: Merricks Letter", 70.401).loop(649615138),
    new Track("Shores of Gold Ambient", 499.284).loop(660473472),
    new Track("Captaincy Theme", 401.975).loop(188339859),
    new Track("Maiden Voyage: Pirate Lord", 164.787).start(923949000).loop(469326123),
    new Track("Arena Countdown", 140.428).loop(966786944),

    // new Track("", -1).start().loop(),

    new Track("Shanty: 1812", 121.871).loop([108465899, 632815062, 381430891, 62236877, 97259024, 452985711, 800799798, 294796207]),
    new Track("Shanty: A Pirates Life For Me", 62.425).loop([530724241, 70316922, 366167156, 593057736, 63952080, 932766317, 849144364, 146594909]),
    new Track("Shanty: Ballad of the Mer", 87.017).loop([847981674, 846195013, 31816430, 984185252, 418815839, 984419573, 1042283134, 3223501]),
    new Track("Shanty: Becalmed", 162.796).loop([884435841, 596671200, 274949474, 1013363951, 1046303580, 930679328, 368880450, 942458596]),
    new Track("Shanty: Bosun Bill", 141.473).loop([180963835, 88307870, 185198915, 141477431, 533583458, 793372873, 714940534, 946555345]),
    new Track("Shanty: Grogg Mayles", 109.220).loop([910374717, 785631650, 111641514, 387736247, 186542033, 1073431028, 156806904, 549349616]),
    new Track("Shanty: Happy Birthday", 54.807).loop([1014683127, 272781140, 562719556, 586291104, 1059606271, 222744779, 430358190, 471087908]),
    new Track("Shanty: Infernal Galop", 133.164).loop([959950147, 596683621, 758464012, 48016215, 970619373, 324014025, 1067848212, 1036463931]),
    new Track("Shanty: Jolly Good Fellow", 24.449).loop([744774265, 57669759, 393095436, 420727645, 747780109, 1025020284, 1007962991, 409728113]),
    new Track("Shanty: Maiden Voyage", 128.692).loop([906224859, 758369423, 308484973, 92974629, 462511634, 496975212, 631810833, 518985868]),
    new Track("Shanty: Monkey Island", 73.668).loop([381280970, 836118178, 229192698, 1011282898, 879337858, 422705762, 586291279, 271368275]),
    new Track("Shanty: Reaper Lair", 51.385).loop([873102121, 44590921, 532638494, 1032492507, 785495221, 171043654, 570013563, 781821201, 1044693042]),
    new Track("Shanty: Ride of the Valkyries", 95.001).loop([816480717, 650087697, 578811040, 204834278, 664328226, 898469950, 854754755, 206825569]),
    new Track("Shanty: Row, Row, Row Your Boat", 44.303).loop([432700265, 453989295, 91905217, 578463455, 1066153129, 897744776, 50300882, 886988451]),
    new Track("Shanty: Seek the Dead", 130.001).loop([475209881, 431502938, 276096762, 1036257497, 435784370, 761692595, 946688523, 479496571]),
    new Track("Shanty: Stitcher's Sorrow", 136.188).loop([315495648, 712103427, 385491853, 669366966, 787308016, 73604760, 782657700, 184256149]),
    new Track("Shanty: Summon the Megadolon", 124.004).loop([877229485, 772625892, 547109501, 389209735, 493871195, 963624697, 114652040, 494361790 ]),
    new Track("Shanty: We Shall Sail Together", 127.188).loop([414741438, 1034292801, 1048957527, 455231921, 674407303, 596526125, 393835952, 1043358093]),
    new Track("Shanty: We Shall Saill Together (Staircase)", 25.004).loop([339299842, 202185660, 267930362, 276375580, 666866941, 498740216, 774249426, 136637716]),
    new Track("Shanty: Who Shall Not Be Returning", 157.223).loop([766838316, 839765749, 44746862, 302481874, 579359301, 131914673, 747030040, 425914254]),
    // new Track("Shanty: ", -1).loop([]),
  ]
}



AppOS.Application?.availableComponents?.push?.(Component)
export { Component, Player, Track }
