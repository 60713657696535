class SimpleEvents {
  static applyOn(target, events = [], opts) {
    if(!Array.isArray(target)) target = Array(target)
    if(target.length == 1) target.push("events")
    if(!target[0][target[1]]) target[0][target[1]] = new SimpleEvents.Events(target[0])

    const tev = target[0][target[1]]
    target[0].on = tev.on.bind(tev)
    target[0].off = tev.off.bind(tev)
    target[0].fire = tev.fire.bind(tev)

    events.forEach(evn => target[0][target[1]].register(evn, opts))
  }
}

SimpleEvents.Events = class {
  constructor(target) {
    this.target = target
    this.callbacks = {}
  }

  on(evn, cb) {
    if(!this.callbacks[evn]) throw(`no such event ${evn}`)
    this.callbacks[evn].push(cb)
    cb.detach = _ => this.off(evn, cb)
    return this.target
  }

  off(evn, cb) {
    if(!this.callbacks[evn]) throw(`no such event ${evn}`)
    const index = this.callbacks[evn].indexOf(cb)
    if(index < 0) throw(`no such handler on ${evn}`)
    this.callbacks[evn].splice(index, 1)
    return this.target
  }

  fire(evn, ...args) {
    if(!this.callbacks[evn]) throw(`no such event ${evn}`)
    this.callbacks[evn].forEach(fn => fn.apply(this.target, args))
    return this.target
  }

  register(evn, opts) {
    if(!this.callbacks[evn]) this.callbacks[evn] = []
    if(opts) {
      const capitalized = `${evn[0].toUpperCase()}${evn.slice(1)}`
      if(opts[`on${capitalized}`]) this.on(evn, opts[`on${capitalized}`])
    }
  }

  // bindSub(bindOn, ...args) {
  //   let prefix = false
  //   let events = args[0]
  //   if(args.length == 2) {
  //     prefix = args[0]
  //     events = args[1]
  //   }
  //   Object.keys(this.callbacks).forEach(evn => {
  //     if (prefix) {
  //       this.target.on(evn, bindOn[`${prefix}${evn[0].toUpperCase()}${evn.slice(1)}`].bind(bindOn))
  //     } else {
  //       this.target.on(evn, bindOn[evn].bind(bindOn))
  //     }
  //   })
  //   return target
  // }
}

export { SimpleEvents }
