import * as AppOS from "./appos"

class Component {
  static DEFAULT_OPTIONS = {
    debug: false,
  }

  static DEFAULT_EVENTS = [
    "init",
    "boot",
    "documentLoad",
    "pageLoad",
  ]

  constructor(app, opts = {}) {
    this.app = app
    this.opts = Object.assign({}, this.constructor.DEFAULT_OPTIONS, opts)
    if(app.opts) this.opts.debug = this.app.opts.debug

    // namespaced console
    AppOS.NamespacedConsole.applyOn(this, { base: this.app, namespace: [this, "name"] })

    // register events
    this.constructor.DEFAULT_EVENTS.forEach(efn => {
      if(typeof this[efn] == "function") this.app.on(efn, this[efn].bind(this))
    })
  }

  get name() {
    return this.constructor.name
  }

  get componentLoader() {
    return window.__appos_core_component_loader
  }

  visitPage(...args) {
    AppOS.Util.visitPage(...args)
  }

  reloadPage (...args) {
    AppOS.Util.reloadPage(...args)
  }
}

export { Component }
